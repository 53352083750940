
.ei-slider{
    position: relative;
    width: 100%;
    max-width: 1920px;
    height: 500px;
    margin: 0 auto;
}
.ei-slider-loading{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index:999;
    background: rgba(0,0,0,0.9);
    color: #fff;
    text-align: center;
    line-height: 400px;
}
.ei-slider-large{
    height: 100%;
    width: 100%;
    position:relative;
    overflow: hidden;
    margin-bottom: 0;
    list-style: none;
}
.ei-slider-large li{
    position: absolute;
    top: 0px;
    left: 0px;
    overflow: hidden;
    height: 100%;
    width: 100%;
}
.ei-slider-large li img{
    width: 100%;
}
.ei-title{
    position: absolute;
    right: auto;
    top: 35%;
    /*max-width: 500px;*/
    left: 50%;
    text-align: center;
    margin-left: -250px;
}

.ei-title h2,
.ei-title h2.dark-txt {
    font-size: 60px;
    margin-bottom: 0;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 300;
    letter-spacing: normal;
}

.ei-title h2, .ei-title h3,
.ei-title p{
    color: #fff;
}

.ei-title p{
    font-size: 18px;
}

.ei-title h2.dark-txt, .ei-title h3.dark-txt,
.ei-title p.dark-txt{
    color: #222;
}

.ei-slider-thumbs{
    height: 10px;
    margin: 0 auto;
    position: relative;
    padding: 0;
    margin-top: -10px;
    z-index: 12;
    list-style: none;
}
.ei-slider-thumbs li{
    position: relative;
    float: left;
    height: 100%;
    padding: 0 2px;
}
.ei-slider-thumbs li.ei-slider-element {
    top: 0px;
    left: 0px;
    position: absolute;
    height: 100%;
    z-index: 10;
    text-indent: -9000px;
    background: rgba(0,0,0,0.9);

}
.ei-slider-thumbs li a{
    display: block;
    text-indent: -9000px;
    background: rgba(0,0,0,.3);
    width: 100%;
    height: 100%;
    cursor: pointer;
    -webkit-transition: background 0.2s ease;
    -moz-transition: background 0.2s ease;
    -o-transition: background 0.2s ease;
    -ms-transition: background 0.2s ease;
    transition: background 0.2s ease;
}
.ei-slider-thumbs li a:hover {
    background-color: rgba(255,255,255,.2);
}
.ei-slider-thumbs li img{
    position: absolute;
    bottom: 50px;
    opacity: 0;
    z-index: 999;
    max-width: 100%;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    transition: all 0.4s ease;
    /*-webkit-box-reflect:*/
    /*below 0px -webkit-gradient(*/
            /*linear,*/
            /*left top,*/
            /*left bottom,*/
            /*from(transparent),*/
            /*color-stop(50%, transparent),*/
            /*to(rgba(255,255,255,0.3))*/
    /*);*/

    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
}
.ei-slider-thumbs li:hover img{
    opacity: 1;
    bottom: 13px;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
@media screen and (max-width: 830px) {
    .ei-title{
        position: absolute;
        right: 0px;
        margin-right: 0px;
        width: 100%;
        text-align: center;
        top: auto;
        bottom: 10px;
        /*background: #fff;*/
        /*background: rgba(255,255,255,0.9);*/
        padding: 5px 0;
    }
    .ei-title h2, .ei-title h3, .ei-title p{
        text-align: center;
    }
    .ei-title h2{
        font-size: 20px;
        line-height: 24px;
    }
    .ei-title h3{
        font-size: 30px;
        line-height: 40px;
    }

}